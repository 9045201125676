.blue {
  color: #49baf1;
}

.dark-blue {
  color: #0f3a57;
}

.dark-blue-50 {
  color: #0f3a5750;
}

.dark-blue-60 {
  color: #0f3a5799;
}

.dark-blue-80 {
  color: #0f3a57cc;
}

.dark-blue-90 {
  color: #0f3a57e6;
}

.white {
  color: #fff;
}

.white-50 {
  color: #ffffff50;
}

.background-blue {
  background: #49baf1;
}

.background-dark-blue {
  background: #0f3a57;
}

/*# sourceMappingURL=index.fe8465f9.css.map */
