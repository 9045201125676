.blue {
    color: #49BAF1;
}

.dark-blue {
    color: #0F3A57;
}

.dark-blue-50 {
    color: #0F3A5750;
}

.dark-blue-60 {
    color: #0F3A5799;
}

.dark-blue-80 {
    color: #0F3A57CC;
}

.dark-blue-90 {
    color: #0F3A57E6;
}

.white {
    color: white;
}

.white-50 {
    color: #FFFFFF50;
}

.background-blue {
    background: #49BAF1;
}

.background-dark-blue {
    background: #0F3A57;
}